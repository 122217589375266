*::-webkit-scrollbar {
  width: 12px;
  background: #999;
}

*::-webkit-scrollbar-thumb {
  background: #555;
}

.swiper-pagination {
  bottom: -6px !important;
  width: 100%;
}

.swiper-wrapper {
  padding-bottom: 20px !important;
}

.swiper-pagination-bullet-active {
  background: #4a5568 !important;
}

.swiper-pagination-bullet {
  background: #718096;
}
